import { PreferencesState } from '@abb-procure/data';
import { BooleanInput } from '@angular/cdk/coercion';
import { NgTemplateOutlet } from '@angular/common';
import {
  Component,
  ContentChildren,
  booleanAttribute,
  computed,
  inject,
  input,
  output,
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { QuicklinkDirective } from 'ngx-quicklink';
import { ViewNavigationService } from '../../services/view-navigation.service';
import { MenuChildComponent } from '../menu-child/menu-child.component';

@Component({
  selector: 'procure-menu-item',
  templateUrl: './menu-item.component.html',
  styleUrls: ['./menu-item.component.scss'],
  standalone: true,
  imports: [
    MatIconModule,
    RouterLink,
    RouterLinkActive,
    NgTemplateOutlet,
    MatTooltipModule,
    QuicklinkDirective,
  ],
})
export class MenuItemComponent {
  @ContentChildren(MenuChildComponent) children!: MenuChildComponent[];

  viewChange = output<string>();

  disabled = input<boolean, BooleanInput>(false, {
    transform: booleanAttribute,
  });

  view = input<string>();
  icon = input<string>();
  link = input<string[]>();
  fragment = input<string>();
  label = input<string>();
  tooltip = input<string>();

  outlined = input<boolean, BooleanInput>(false, {
    transform: booleanAttribute,
  });

  primary = input<boolean, BooleanInput>(false, {
    transform: booleanAttribute,
  });

  faded = input<boolean, BooleanInput>(false, {
    transform: booleanAttribute,
  });

  partialMatch = input<boolean, BooleanInput>(false, {
    transform: booleanAttribute,
  });

  showChildren = input<boolean, BooleanInput>(true, {
    transform: booleanAttribute,
  });

  secondary = input<boolean, BooleanInput>(false, {
    transform: booleanAttribute,
  });

  active = input<boolean, BooleanInput>(false, {
    transform: booleanAttribute,
  });

  warning = input<boolean, BooleanInput>(false, {
    transform: booleanAttribute,
  });

  protected itemTooltip = computed(() =>
    !this.expanded() ? this.label() : this.tooltip(),
  );

  protected preferences = inject(PreferencesState);

  protected expanded = computed(() =>
    !this.secondary()
      ? this.preferences.isNavigationExpanded()
      : this.preferences.isSecondaryNavigationExpanded(),
  );

  protected readonly viewNavigationService = inject(ViewNavigationService);

  protected activeView = this.viewNavigationService.activeView;

  navigate(): void {
    const view = this.view();

    if (!view) {
      return;
    }

    this.viewNavigationService.setActiveView(view);

    const url = new URL(window.location.toString());
    url.searchParams.set('view', view);

    window.history.pushState({}, '', url);
  }
}
