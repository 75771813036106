@if (!link()) {
  <div
    [class.tag--color]="colored()"
    [class]="'tag tag--' + userStatus()?.status?.color"
    [class.tag--active]="active()"
    [class.tag--selectable]="selectable()"
    [id]="id()"
    [routerLink]="userStatusLink()"
    [matTooltip]="(userStatus() ? userStatus()?.status?.name : tooltip()) ?? ''"
  >
    <ng-container *ngTemplateOutlet="content" />
  </div>
} @else {
  <a
    class="tag"
    [id]="id()"
    [routerLink]="link()"
    [matTooltip]="tooltip() ?? ''"
  >
    <ng-container *ngTemplateOutlet="content" />
    <mat-icon
      class="tag__button"
      [routerLink]="link()"
    >
      arrow_forward
    </mat-icon>
  </a>
}

<ng-template #content>
  @if (user(); as user) {
    <procure-avatar
      class="tag__avatar"
      [name]="user.name"
      [avatar]="user.avatarUrl"
    />
  }
  <div class="tag__content">
    <ng-content />
  </div>
  @if (clearable()) {
    <mat-icon
      class="tag__button"
      (click)="clear.emit()"
    >
      clear
    </mat-icon>
  }
</ng-template>
