import { UserModel, UserSlim, UserStatusModel } from '@abb-procure/data';
import { BooleanInput } from '@angular/cdk/coercion';
import { NgTemplateOutlet } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  booleanAttribute,
  computed,
  input,
  output,
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterLink } from '@angular/router';
import { deepEqual } from 'fast-equals';
import { AvatarComponent } from '../avatar/avatar.component';

@Component({
  selector: 'procure-tag',
  templateUrl: './tag.component.html',
  styleUrls: ['./tag.component.scss'],
  standalone: true,
  imports: [
    MatIconModule,
    AvatarComponent,
    RouterLink,
    NgTemplateOutlet,
    MatTooltipModule,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TagComponent {
  clear = output<void>();

  id = input<string | null>(null);

  colored = input<boolean, BooleanInput>(false, {
    transform: booleanAttribute,
  });

  clearable = input<boolean, BooleanInput>(false, {
    transform: booleanAttribute,
  });

  selectable = input<boolean, BooleanInput>(false, {
    transform: booleanAttribute,
  });

  user = input<UserModel | UserSlim | UserStatusModel | null>();
  link = input<string | null>();
  tooltip = input<string | null>();

  active = input<boolean, BooleanInput>(false, {
    transform: booleanAttribute,
  });

  userStatus = computed(
    () =>
      this.user() instanceof UserStatusModel
        ? (this.user() as UserStatusModel)
        : null,

    { equal: (a, b) => a?.id === b?.id },
  );

  userStatusLink = computed(
    () => {
      const userStatus = this.userStatus();

      if (
        !this.clearable() ||
        !userStatus ||
        !userStatus.canView ||
        !userStatus.entityNumber
      ) {
        return null;
      }

      return ['/', userStatus.entityNumber, 'survey-evaluation'];
    },
    { equal: deepEqual },
  );
}
