import { UserStatus } from '../types/user-status.type';
import { EntityStatusModel } from './entity-status.model';
import { UserModel } from './user.model';

export class UserStatusModel extends UserModel implements UserStatus {
  readonly status: EntityStatusModel | null;
  readonly entityNumber: string | null;
  readonly canView: boolean | null;

  constructor(userStatus: UserStatus) {
    super(userStatus);

    this.entityNumber = userStatus.entityNumber;
    this.canView = userStatus.canView;

    this.status = userStatus.status
      ? new EntityStatusModel(userStatus.status)
      : null;
  }

  getDisplay(): string {
    return `${this.name}`;
  }
}
