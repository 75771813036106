import { UserModel, UserSlimModel, UserStatusModel } from '@abb-procure/data';
import { BooleanInput } from '@angular/cdk/coercion';
import {
  Component,
  booleanAttribute,
  computed,
  input,
  numberAttribute,
  output,
} from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { deepEqual } from 'fast-equals';
import { TagComponent } from '../tag/tag.component';

export type Displayable = {
  uuid: string;
  getDisplay(): string;
};

@Component({
  selector: 'procure-tag-list',
  templateUrl: './tag-list.component.html',
  styleUrls: ['./tag-list.component.scss'],
  standalone: true,
  imports: [TagComponent, MatTooltipModule],
})
export class TagListComponent {
  clear = output<Displayable | string>();
  selectActive = output<Displayable | string>();

  tags = input<(Displayable | string)[] | null>(null);

  users = input<(UserModel | UserSlimModel | UserStatusModel | null)[] | null>(
    null,
  );

  maxVisible = input<number, number | string>(0, {
    transform: numberAttribute,
  });

  clearable = input<boolean, BooleanInput>(false, {
    transform: booleanAttribute,
  });

  colored = input<boolean, BooleanInput>(false, {
    transform: booleanAttribute,
  });

  displayLabel = input<string>();
  active = input<(Displayable | string) | null>(null);

  readonly limitedTags = computed(
    () => {
      const tags = this.tags();
      const maxVisible = this.maxVisible();

      if (!tags) {
        return [];
      }

      const limit = maxVisible > 0 ? maxVisible : tags.length;
      return tags.slice(0, limit);
    },
    { equal: deepEqual },
  );

  readonly ellipsis = computed(() => {
    const tags = this.tags();
    const maxVisible = this.maxVisible();

    if (!tags || !maxVisible) {
      return false;
    }

    return tags.length > maxVisible;
  });

  isString(value: unknown): value is string {
    return typeof value === 'string';
  }

  isActive(item: Displayable | string): boolean {
    const active = this.active();

    if (this.isString(item) || this.isString(active)) {
      return item === active;
    }

    return item?.uuid === active?.uuid;
  }

  trackByFn(_index: number, item: Displayable | string | null): string | null {
    if (item === null) {
      return null;
    }

    return typeof item === 'string' ? item : item.uuid;
  }

  protected getDisplay(item: Displayable | string): string {
    const displayLabel = this.displayLabel();

    if (this.isString(item)) {
      return item;
    }

    if (displayLabel) {
      return `${item[displayLabel as keyof typeof item]}`;
    }

    return item.getDisplay();
  }
}
