@if (view(); as view) {
  <button
    class="menu-item"
    data-testid="item"
    [matTooltipPosition]="expanded() ? 'right' : 'below'"
    [class.menu-item--expanded]="expanded()"
    [class.menu-item--active]="activeView() === view || active()"
    [class.menu-item--faded]="faded()"
    [class.menu-item--primary]="primary()"
    [class.menu-item--has-children]="children.length"
    [matTooltip]="itemTooltip() ?? ''"
    [disabled]="disabled()"
    (click)="navigate()"
  >
    <div
      class="menu-item__content"
      [class.menu-item__content--has-children]="children.length"
    >
      @if (icon(); as icon) {
        @if (outlined()) {
          <mat-icon
            class="menu-item__outlined"
            fontSet="material-symbols-outlined"
          >
            {{ icon }}
          </mat-icon>
        } @else {
          <mat-icon>
            {{ icon }}
          </mat-icon>
        }
      }
      @if (warning() && expanded()) {
        <mat-icon>warning</mat-icon>
      }
      @if (expanded()) {
        {{ label() }}
      }
    </div>
  </button>
  <ng-container *ngTemplateOutlet="content" />
}
@if (link(); as link) {
  <a
    class="menu-item"
    data-testid="item"
    matTooltipPosition="right"
    [routerLink]="link"
    [routerLinkActive]="'menu-item--active'"
    [routerLinkActiveOptions]="{ exact: !partialMatch() }"
    [fragment]="fragment()"
    [class.menu-item--primary]="primary()"
    [matTooltip]="itemTooltip() ?? ''"
  >
    <span class="menu-item__content">
      @if (icon(); as icon) {
        @if (outlined()) {
          <mat-icon
            class="menu-item__outlined"
            fontSet="material-symbols-outlined"
          >
            {{ icon }}
          </mat-icon>
        } @else {
          <mat-icon>
            {{ icon }}
          </mat-icon>
        }
      }
      @if (expanded()) {
        {{ label() }}
      }
    </span>
  </a>
  <ng-container *ngTemplateOutlet="content" />
}
@if (!link() && !view()) {
  <button
    class="menu-item"
    data-testid="item"
    matTooltipPosition="right"
    [disabled]="disabled()"
    [class.menu-item--primary]="primary()"
    [class.menu-item--disabled]="disabled()"
    [matTooltip]="itemTooltip() ?? ''"
  >
    <div class="menu-item__content">
      @if (icon(); as icon) {
        @if (outlined()) {
          <mat-icon
            class="menu-item__outlined"
            fontSet="material-symbols-outlined"
          >
            {{ icon }}
          </mat-icon>
        } @else {
          <mat-icon>
            {{ icon }}
          </mat-icon>
        }
      }
      @if (expanded()) {
        {{ label() }}
      }
    </div>
  </button>
  <ng-container *ngTemplateOutlet="content" />
}

<ng-template #content>
  @if (children.length && showChildren()) {
    <div class="menu-item__children">
      <ng-content />
    </div>
  }
</ng-template>
