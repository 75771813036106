import { LookupDataResponse } from '../../types/lookup/lookup-data-response.type';
import { RequirementFields } from '../../types/requirements-fields.type';
import { ContractualMilestoneStatusModel } from '../contractual-milestone-status.model';
import { ContractualMilestoneModel } from '../contractual-milestone.model';
import { CostAllocationModel } from '../cost-allocation.model';
import { DefaultCriteriaDimensionModel } from '../default-criteria-dimension.model';
import { DeliveryDestinationModel } from '../delivery-destination.model';
import { EntityStatusModel } from '../entity-status.model';
import { IdNameCodeModel } from '../id-name-code.model';
import { IdNameDescriptionModel } from '../id-name-description.model';
import { IdNameModel } from '../id-name.model';
import { ResourceVariablesModel } from '../management/resources/resource-variables.model';
import { OfferedLeadTimeModel } from '../offered-lead-time.model';
import { OrderAcknowledgementReviewModel } from '../order-acknowledgement-review.model';
import { SupplierSendTypeModel } from '../supplier-send-type.model';
import { SupplierTypeModel } from '../supplier-type.model';
import { TermsAndConditionsCategoryModel } from '../terms-and-conditions-category.model';

export class LookupDataResponseModel implements LookupDataResponse {
  readonly eTag: string;
  readonly countries: IdNameCodeModel[];
  readonly currencies: IdNameCodeModel[];
  readonly incotermsTypes: IdNameCodeModel[];
  readonly paymentTerms: IdNameModel[];
  readonly termsAndConditionCategories: TermsAndConditionsCategoryModel[];
  readonly wbsCodes: IdNameModel[];
  readonly offeredLeadTimes: OfferedLeadTimeModel[];
  readonly deliveryDestinationTypes: DeliveryDestinationModel[];
  readonly furtherDeliveryDestinationTypes: DeliveryDestinationModel[];
  readonly costAllocationTypes: CostAllocationModel[];
  readonly supplierSendTypes: SupplierSendTypeModel[];
  readonly orderAcknowledgementReviews: OrderAcknowledgementReviewModel[];
  readonly projectProcurementTypes: IdNameModel[];
  readonly projectClassifications: IdNameModel[];
  readonly projectTypeSupplies: IdNameModel[];
  readonly projectStatuses: EntityStatusModel[];
  readonly projectInExecutionSubstatuses: IdNameModel[];
  readonly requestForRequestForQuotationStatuses: EntityStatusModel[];
  readonly requestForQuotationStatuses: EntityStatusModel[];
  readonly templateRequestForRequestForQuotationStatuses: EntityStatusModel[];
  readonly categoryStatuses: EntityStatusModel[];
  readonly quotationStatuses: EntityStatusModel[];
  readonly purchaseRequisitionStatuses: EntityStatusModel[];
  readonly contractualMilestones: ContractualMilestoneModel[];
  readonly contractualMilestoneStatuses: ContractualMilestoneStatusModel[];
  readonly supplierTypes: SupplierTypeModel[];
  readonly requestForRequestForQuotationClassifications: IdNameModel[];
  readonly requestForRequestForQuotationSubstatuses: IdNameModel[];
  readonly quotationSubstatuses: IdNameModel[];
  readonly requestForQuotationSubstatuses: IdNameModel[];
  readonly supplierEvaluationEvaluatorRoles: IdNameModel[];
  readonly supplierPerformanceEvaluationStatuses: EntityStatusModel[];
  readonly projectSupplierPerformanceEvaluationStatuses: EntityStatusModel[];

  readonly requirementFields: RequirementFields = {
    commercial: [],
    logistical: [],
    technical: [],
  };

  readonly notificationReminderExecutionPeriod: IdNameModel[];
  readonly categoryDimensionTaskStatuses: EntityStatusModel[];
  readonly strategyDimensionLevers: IdNameDescriptionModel[];
  readonly mdfCodes: IdNameCodeModel[];
  readonly defaultDimensions: DefaultCriteriaDimensionModel[];
  readonly resourceVariables: ResourceVariablesModel[];

  // eslint-disable-next-line max-lines-per-function, complexity
  constructor(data: LookupDataResponse) {
    this.eTag = data.eTag;

    this.countries =
      data.countries?.map((item) => new IdNameCodeModel(item)) ?? [];

    this.currencies =
      data.currencies?.map((item) => new IdNameCodeModel(item)) ?? [];

    this.incotermsTypes =
      data.incotermsTypes?.map((item) => new IdNameCodeModel(item)) ?? [];

    this.paymentTerms =
      data.paymentTerms?.map((item) => new IdNameModel(item)) ?? [];

    this.termsAndConditionCategories =
      data.termsAndConditionCategories?.map(
        (item) => new TermsAndConditionsCategoryModel(item),
      ) ?? [];

    this.wbsCodes = data.wbsCodes?.map((item) => new IdNameModel(item)) ?? [];

    this.offeredLeadTimes =
      data.offeredLeadTimes?.map((item) => new OfferedLeadTimeModel(item)) ??
      [];

    this.deliveryDestinationTypes =
      data.deliveryDestinationTypes?.map(
        (item) => new DeliveryDestinationModel(item),
      ) ?? [];

    this.furtherDeliveryDestinationTypes =
      data.furtherDeliveryDestinationTypes?.map(
        (item) => new DeliveryDestinationModel(item),
      ) ?? [];

    this.costAllocationTypes =
      data.costAllocationTypes?.map((item) => new CostAllocationModel(item)) ??
      [];

    this.supplierSendTypes =
      data.supplierSendTypes?.map((item) => new SupplierSendTypeModel(item)) ??
      [];

    this.orderAcknowledgementReviews =
      data.orderAcknowledgementReviews?.map(
        (item) => new OrderAcknowledgementReviewModel(item),
      ) ?? [];

    this.projectProcurementTypes =
      data.projectProcurementTypes?.map((item) => new IdNameModel(item)) ?? [];

    this.projectClassifications =
      data.projectClassifications?.map((item) => new IdNameModel(item)) ?? [];

    this.projectTypeSupplies =
      data.projectTypeSupplies?.map((item) => new IdNameModel(item)) ?? [];

    this.projectStatuses =
      data.projectStatuses?.map((item) => new EntityStatusModel(item)) ?? [];

    this.projectInExecutionSubstatuses =
      data.projectInExecutionSubstatuses?.map(
        (item) => new IdNameModel(item),
      ) ?? [];

    this.requestForRequestForQuotationStatuses =
      data.requestForRequestForQuotationStatuses?.map(
        (item) => new EntityStatusModel(item),
      ) ?? [];

    this.templateRequestForRequestForQuotationStatuses =
      data.templateRequestForRequestForQuotationStatuses?.map(
        (item) => new EntityStatusModel(item),
      ) ?? [];

    this.categoryStatuses =
      data.categoryStatuses?.map((item) => new EntityStatusModel(item)) ?? [];

    this.requestForQuotationStatuses =
      data.requestForQuotationStatuses?.map(
        (item) => new EntityStatusModel(item),
      ) ?? [];

    this.quotationStatuses =
      data.quotationStatuses?.map((item) => new EntityStatusModel(item)) ?? [];

    this.purchaseRequisitionStatuses =
      data.purchaseRequisitionStatuses?.map(
        (item) => new EntityStatusModel(item),
      ) ?? [];

    this.contractualMilestones =
      data.contractualMilestones?.map(
        (item) => new ContractualMilestoneModel(item),
      ) ?? [];

    this.contractualMilestoneStatuses =
      data.contractualMilestoneStatuses?.map(
        (item) => new ContractualMilestoneStatusModel(item),
      ) ?? [];

    this.supplierTypes =
      data.supplierTypes?.map((item) => new SupplierTypeModel(item)) ?? [];

    this.requestForRequestForQuotationClassifications =
      data.requestForRequestForQuotationClassifications?.map(
        (item) => new IdNameModel(item),
      ) ?? [];

    this.requestForRequestForQuotationSubstatuses =
      data.requestForRequestForQuotationSubstatuses?.map(
        (item) => new IdNameModel(item),
      ) ?? [];

    this.quotationSubstatuses =
      data.quotationSubstatuses?.map((item) => new IdNameModel(item)) ?? [];

    this.requestForQuotationSubstatuses =
      data.requestForQuotationSubstatuses?.map(
        (item) => new IdNameModel(item),
      ) ?? [];

    if (data.requirementFields) {
      this.requirementFields = data.requirementFields;
    }

    this.notificationReminderExecutionPeriod =
      data.notificationReminderExecutionPeriod?.map(
        (item) => new IdNameModel(item),
      ) ?? [];

    this.categoryDimensionTaskStatuses =
      data.categoryDimensionTaskStatuses?.map(
        (item) => new EntityStatusModel(item),
      ) ?? [];

    this.strategyDimensionLevers =
      data.strategyDimensionLevers?.map(
        (item) => new IdNameDescriptionModel(item),
      ) ?? [];

    this.mdfCodes =
      data.mdfCodes?.map((item) => new IdNameCodeModel(item)) ?? [];

    this.defaultDimensions =
      data.defaultDimensions?.map(
        (item) => new DefaultCriteriaDimensionModel(item),
      ) ?? [];

    this.resourceVariables =
      data.resourceVariables?.map((item) => new ResourceVariablesModel(item)) ??
      [];

    this.supplierEvaluationEvaluatorRoles =
      data.supplierEvaluationEvaluatorRoles?.map(
        (item) => new IdNameModel(item),
      ) ?? [];

    this.supplierPerformanceEvaluationStatuses =
      data.supplierPerformanceEvaluationStatuses?.map(
        (item) => new EntityStatusModel(item),
      ) ?? [];

    this.projectSupplierPerformanceEvaluationStatuses =
      data.projectSupplierPerformanceEvaluationStatuses?.map(
        (item) => new EntityStatusModel(item),
      ) ?? [];
  }
}
