@if (route(); as route) {
  @if (route.length > 0) {
    <div
      class="menu-child"
      data-testid="item"
      matTooltipPosition="right"
      [class.menu-child--last]="last()"
      [class.menu-child--show]="show()"
      [class.menu-child--expanded]="expanded()"
      [matTooltip]="!expanded() ? tooltip() || '' : ''"
    >
      <a
        class="menu-child__link"
        [class.menu-child__link--expanded]="expanded()"
        [routerLink]="route"
        [class.menu-child__content--active]="active()"
      >
        @if (expanded()) {
          <ng-container *ngTemplateOutlet="content" />
        }
      </a>
    </div>
  }
}
@if (view(); as view) {
  @if (view.length !== 0) {
    <button
      class="menu-child"
      data-testid="item"
      matTooltipPosition="right"
      [class.menu-child--last]="last()"
      [class.menu-child--show]="show()"
      [class.menu-child--expanded]="expanded()"
      [matTooltip]="!expanded() ? tooltip() || '' : ''"
      (click)="navigate()"
    >
      <span
        class="menu-child__content"
        [class.menu-child__content--active]="active()"
      >
        @if (expanded()) {
          <ng-container *ngTemplateOutlet="content" />
        }
      </span>
    </button>
  }
}
<div
  class="menu-child__tree-indicator"
  [class.menu-child__tree-indicator--expanded]="expanded()"
>
  <div
    class="menu-child__vertical-line"
    [class.menu-child__vertical-line--last]="last()"
    [class.menu-child__vertical-line--first]="first()"
    [class.menu-child__vertical-line--expanded]="expanded()"
  ></div>
  <div
    class="menu-child__horizontal-line"
    [class.menu-child__horizontal-line--last]="last()"
    [class.menu-child__horizontal-line--first]="first()"
    [class.menu-child__horizontal-line--show]="show()"
    [class.menu-child__horizontal-line--expanded]="expanded()"
  ></div>
</div>

<ng-template #content>
  <ng-content />
</ng-template>
