import { ConnectedPosition } from '@angular/cdk/overlay';

export const DEFAULT_RETURN_URL = '/';
export const DEBOUNCE_INPUT_CHANGE_TIME_SMALL: number = 200;
export const DEBOUNCE_INPUT_CHANGE_TIME_BASE: number = 400;

export const DECIMAL_PATTERN: string = '^-?\\d+\\.?\\d*$';

export const MAX_LOGIN_ATTEMPTS = 3;

export const DEFAULT_DATE = new Date(1901, 0, 1, 0, 0, 0);

export const MIN_QUERY_CHARACTERS = 3;

export const MAX_RANGE_VALUE = 100000;

export const EMPTY_GUID = '00000000-0000-0000-0000-000000000000';

export const DEFAULT_TRUNCATED_STRING_LENGTH = 140;

export const DEFAULT_SMALL_DIALOG_WIDTH = '450px';
export const DEFAULT_DIALOG_WIDTH = '500px';
export const DEFAULT_MIDDLE_DIALOG_WIDTH = '1000px';
export const DEFAULT_BIG_DIALOG_WIDTH = '1500px';

export const MAX_INT = 2000000000;

export const DEFAULT_LAYOUT_GAP = 10;

export const UNREAD_NOTIFICATIONS_LIMIT = 99;

export const FILE_EXTENSION_BLACKLIST = [
  'com',
  'exe',
  'dll',
  'ocx',
  'js',
  'jse',
  'vbs',
  'vb',
  'vbe',
  'cmd',
  'bat',
  'hta',
  'inf',
  'reg',
  'pif',
  'scr',
  'cpl',
  'scf',
  'msc',
  'pol',
  'hip',
  'chm',
  'ws',
  'wsf',
  'wsc',
  'wsh',
  'jar',
  'rar',
  'bz2',
  'cab',
  'gz',
  'tar',
  'ace',
  'ppkg',
  'application',
  'xlsb',
  'xlam',
  'xll',
  'xlw',
  'z',
];

export const MAX_FILE_SIZE = 2147483648;
export const MAX_FILES_PER_FIELD = 20;

export const OVERLAY_POSITIONS: ConnectedPosition[] = [
  {
    originX: 'end',
    originY: 'bottom',
    overlayX: 'end',
    overlayY: 'top',
  },
  {
    originX: 'start',
    originY: 'bottom',
    overlayX: 'start',
    overlayY: 'top',
  },
];

export const SPECIAL_PAYMENT_TERMS_ID = 13;
